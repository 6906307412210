import React, { memo, ReactElement, useRef } from 'react';
import cnBind from 'classnames/bind';

import styles from './Slides.module.scss';

const cx = cnBind.bind(styles);

export const Slides: React.FC<{
    onSlideClick?: (value: number) => void;
    isTransitionEnabled: boolean;
    children: ReactElement[];
    additionalElementsCount: number;
    isCards?: boolean;
}> = memo(({ onSlideClick, isTransitionEnabled, additionalElementsCount, isCards, children }) => {
    const slideAmount = children.length;
    const slideRef = useRef<HTMLLIElement>(null);

    const extraPreviousItems = React.useMemo(() => {
        return children.slice(slideAmount - additionalElementsCount).map((child, index) => (
            <li className={cx('slide', { 'slide--card': isCards })} key={`${index}-block-prev`}>
                <button
                    type="button"
                    className={cx('slide-button')}
                    onPointerUp={onSlideClick ? () => onSlideClick(index) : undefined}
                >
                    {child}
                </button>
            </li>
        ));
    }, [children, slideAmount, additionalElementsCount, isCards, onSlideClick]);

    const extraNextItems = React.useMemo(() => {
        return children.slice(0, additionalElementsCount).map((child, index) => (
            <li className={cx('slide', { 'slide--card': isCards })} key={`${index}-block-next`}>
                <button
                    type="button"
                    className={cx(['slide-button'])}
                    onPointerUp={onSlideClick ? () => onSlideClick(index + additionalElementsCount * 2) : undefined}
                >
                    {child}
                </button>
            </li>
        ));
    }, [children, additionalElementsCount, isCards, onSlideClick]);

    return (
        <>
            {extraPreviousItems}
            {children.map((child, index) => (
                <li className={cx('slide', { 'slide--card': isCards })} ref={slideRef} key={`${index}-block`}>
                    <button
                        type="button"
                        className={cx('slide-button')}
                        style={{ transition: !isTransitionEnabled ? 'none' : undefined }}
                        onPointerUp={onSlideClick ? () => onSlideClick(index + additionalElementsCount) : undefined}
                    >
                        {child}
                    </button>
                </li>
            ))}
            {extraNextItems}
        </>
    );
});
