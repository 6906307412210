import React, { memo, ReactElement } from 'react';
import cnBind from 'classnames/bind';
import { TabsColorSchema } from 'types';

import styles from './Tabs.module.scss';

const cx = cnBind.bind(styles);

export const Tabs: React.FC<{
    entities: ReactElement[];
    activeIndex: number;
    colorSchema?: TabsColorSchema;
    className?: string;
}> = memo(({ entities, activeIndex, colorSchema = TabsColorSchema.DarkBlue, className }) => (
    <ul className={cx('tabs', className)}>
        {entities.map((_, index) => (
            <li
                key={`dot-${index}`}
                className={cx(
                    'tab',
                    `tab--${colorSchema}`,
                    { 'tab--active': activeIndex === index },
                    { [`tab--active--${colorSchema}`]: activeIndex === index },
                )}
            />
        ))}
    </ul>
));
