import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { Logo } from 'assets';

import styles from './Header.module.scss';

const cx = cnBind.bind(styles);

export const Header: FC = memo(() => (
    <header className={cx('header')}>
        <div className={cx('cover-wrapper')}>
            <picture>
                <source
                    media="(min-width:960px)"
                    srcSet={`${process.env.PUBLIC_URL}/welcome-bg-desktop.webp`}
                    type="image/webp"
                />
                <source
                    media="(max-width:959px)"
                    srcSet={`${process.env.PUBLIC_URL}/welcome-bg-mobile.webp`}
                    type="image/webp"
                />
                <img
                    className={cx('cover')}
                    src={`${process.env.PUBLIC_URL}/welcome-bg-desktop.webp`}
                    width={1920}
                    height={458}
                    alt="Three girls in anime style playing Happy Abby."
                />
            </picture>
        </div>
        <div className={cx('logo-wrapper')}>
            <Logo />
        </div>
    </header>
));
