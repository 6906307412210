import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { FeaturesComponentContent } from 'types';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { ResponsiveImage } from 'components/ResponsiveImage';

import styles from './Features.module.scss';

const cx = cnBind.bind(styles);

export const Features: FC<{ content: FeaturesComponentContent }> = memo(({ content }) => (
    <section className={cx('features')}>
        <Text type={TextStyles.TITLE} className="visually-hidden">
            {content.title}
        </Text>
        {/* FIXME: CHANGE CARDS LAYOUT!!! */}
        <div className={cx('wrapper')}>
            <ul className={cx('features-list')}>
                {content.features.map((feature, index) => {
                    const [title, sources] = Object.entries(feature).flat(1);

                    return (
                        <li className={cx('features-item')} key={`${title}${index}-feature`}>
                            <div className={cx('image-wrapper')}>
                                <ResponsiveImage sources={sources} className={cx('image')} />
                            </div>
                            <Text type={TextStyles.PARAGRAPH} align="center" className={cx('features-card-text')}>
                                {title}
                            </Text>
                        </li>
                    );
                })}
            </ul>
        </div>
    </section>
));
