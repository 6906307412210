import { ReactNode } from 'react';

export enum TextStyles {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    PARAGRAPH = 'paragraph',
}

export interface TextComponentProps {
    type?: TextStyles;
    color?: string;
    align?: 'center' | 'right';
    className?: string;
    children: ReactNode;
}
