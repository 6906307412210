import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { TestimonialCardComponentContent } from 'types';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { GeotagIcon, StarIcon } from 'assets';

import styles from './TestimonialCard.module.scss';

const cx = cnBind.bind(styles);

export const TestimonialCard: FC<{ content: TestimonialCardComponentContent }> = memo(({ content }) => (
    <article className={cx('testimonial-card')}>
        {content?.title && (
            <Text type={TextStyles.SUBTITLE} className={cx('card-title')}>
                {content.title}
            </Text>
        )}
        <div className={cx('card-content')}>
            <Text type={TextStyles.PARAGRAPH} className={cx('review-text')}>
                {content.review}
            </Text>
            <footer className={cx('review-info')}>
                <p className={cx('review-stars')}>
                    {Array(content.starsCount)
                        .fill(null)
                        .map((_, index) => (
                            <StarIcon key={`stars-${index}`} />
                        ))}
                </p>
                <div className={cx('author')}>
                    <Text>{content.name}</Text>
                    <Text className={cx('location')} type={TextStyles.PARAGRAPH}>
                        <GeotagIcon />
                        {content.country}
                    </Text>
                </div>
            </footer>
        </div>
    </article>
));
