import React, { memo } from 'react';
import cnBind from 'classnames/bind';
import { SliderControlType } from 'types';
import { SliderArrowIcon } from 'assets';

import styles from './SliderControl.module.scss';

const cx = cnBind.bind(styles);

export const SliderControl: React.FC<{
    type: SliderControlType;
    isDisabled?: boolean;
    className?: string;
    handleClick: (event: React.PointerEvent<HTMLButtonElement>) => void;
}> = memo(({ type, isDisabled = false, className, handleClick }) => (
    <div className={cx('control-wrapper', { 'control-wrapper--right': type === SliderControlType.Next }, className)}>
        <button
            className={cx('control-button', { 'control-button--reversed': type === SliderControlType.Next })}
            type="button"
            onClick={handleClick}
            disabled={isDisabled}
        >
            <span className={cx('visually-hidden')}>Show {type} slide</span>
            <SliderArrowIcon />
        </button>
    </div>
));
