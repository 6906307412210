import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { DarkLogo } from 'assets';

import { FOOTER_SECTION_CONTENT } from '../../constants';

import styles from './Footer.module.scss';

const cx = cnBind.bind(styles);

export const Footer: FC<{ copyright: string; links: typeof FOOTER_SECTION_CONTENT.links }> = memo(
    ({ copyright, links }) => (
        <footer className={cx('footer')}>
            <div className={cx('footer-wrapper')}>
                <div className={cx('footer-navigation-wrapper')}>
                    <nav className={cx('footer-nav')}>
                        <ul className={cx('nav-list')}>
                            {links.map((entry) => {
                                const [text, link] = Object.entries(entry).flat();

                                return (
                                    <li className={cx('nav-item')} key={`${text}${link}`}>
                                        <a href={`/${link}`}>{text}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
                <div className={cx('copyright-wrapper')}>
                    <a className={cx('logo-wrapper')} href="/">
                        <DarkLogo />
                    </a>
                    <Text className={cx('copyright-text')} type={TextStyles.PARAGRAPH}>
                        {copyright}
                    </Text>
                </div>
            </div>
        </footer>
    ),
);
