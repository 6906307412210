import {
    DressvilleIconDesktop,
    DressvilleIconDesktop2x,
    DressvilleIconDesktopWebp,
    DressvilleIconDesktopWebp2x,
    FeatureIcon1Desktop,
    FeatureIcon1Desktop2x,
    FeatureIcon1DesktopWebp,
    FeatureIcon1DesktopWebp2x,
    FeatureIcon1Mobile,
    FeatureIcon1Mobile2x,
    FeatureIcon1MobileWebp,
    FeatureIcon1MobileWebp2x,
    FeatureIcon2Desktop,
    FeatureIcon2Desktop2x,
    FeatureIcon2DesktopWebp,
    FeatureIcon2DesktopWebp2x,
    FeatureIcon2Mobile,
    FeatureIcon2Mobile2x,
    FeatureIcon2MobileWebp,
    FeatureIcon2MobileWebp2x,
    FeatureIcon3Desktop,
    FeatureIcon3Desktop2x,
    FeatureIcon3DesktopWebp,
    FeatureIcon3DesktopWebp2x,
    FeatureIcon3Mobile,
    FeatureIcon3Mobile2x,
    FeatureIcon3MobileWebp,
    FeatureIcon3MobileWebp2x,
    FeatureIcon4Desktop,
    FeatureIcon4Desktop2x,
    FeatureIcon4DesktopWebp,
    FeatureIcon4DesktopWebp2x,
    FeatureIcon4Mobile,
    FeatureIcon4Mobile2x,
    FeatureIcon4MobileWebp,
    FeatureIcon4MobileWebp2x,
    GalleryImage1,
    GalleryImage1Webp,
    GalleryImage1Webp2x,
    GalleryImage2,
    GalleryImage2Webp,
    GalleryImage2Webp2x,
    GalleryImage3,
    GalleryImage3Webp,
    GalleryImage3Webp2x,
    GalleryImage4,
    GalleryImage4Webp,
    GalleryImage4Webp2x,
    GalleryImage5,
    GalleryImage5Webp,
    GalleryImage5Webp2x,
    GalleryImage6,
    GalleryImage6Webp,
    GalleryImage6Webp2x,
} from 'assets';
import { ImageSources, PageContent } from 'types';

// SECTION: Constants
export const DOMAIN_WITHOUT_PROTOCOL = 'happyabby.fun';
export const DOMAIN = 'https://happyabby.fun/';
export const PRIVACY_PAGE_NAME = 'Privacy Policy';
export const PRIVACY_PAGE_LINK = 'privacy';
export const TERMS_PAGE_NAME = 'Terms of service';
export const TERMS_PAGE_LINK = 'terms';
export const PAGE_ID = 'ROOT_PAGE';

const privacyPageUrl = `${DOMAIN}${PRIVACY_PAGE_LINK}`;
const termsPageUrl = `${DOMAIN}${TERMS_PAGE_LINK}`;
const copyrightEmail = `copyright@${DOMAIN_WITHOUT_PROTOCOL}`;
const privacyEmail = `privacy@${DOMAIN_WITHOUT_PROTOCOL}`;
const suppportEmail = `support@${DOMAIN_WITHOUT_PROTOCOL}`;
const googlePrivacyUrl = 'https://policies.google.com/privacy';
const ampllitudePrivacyUrl = 'https://amplitude.com/privacy';
const metrikaPricayPolicy = 'https://yandex.ru/support/metrica/general/confidential-data.html?lang=ru';

export const GALLERY_SLIDE_WIDTH = 200;
export const GALLERY_SLIDES_COLUMN_GAP = 16;
export const GALLERY_SLIDER_FULL_WIDTH = 1280;

export const CARDS_SLIDE_WIDTH = 311;
export const CARDS_SLIDES_COLUMN_GAP = 8;
export const CARDS_SLIDER_FULL_WIDTH = 1587;

// SECTION: Texts
export const FEATURES_SECTION_CONTENT = {
    title: 'Dressville - City of Fashion',
    features: [
        {
            'Only pleasant emotions': {
                desktop: {
                    webp: [FeatureIcon1DesktopWebp, FeatureIcon1DesktopWebp2x],
                    jpeg: [FeatureIcon1Desktop, FeatureIcon1Desktop2x],
                },
                mobile: {
                    webp: [FeatureIcon1MobileWebp, FeatureIcon1MobileWebp2x],
                    jpeg: [FeatureIcon1Mobile, FeatureIcon1Mobile2x],
                },
                description: 'Girl is playing the game and having fun.',
                width: 190,
                height: 203,
            },
        },
        {
            'World of creativity & fun': {
                desktop: {
                    webp: [FeatureIcon2DesktopWebp, FeatureIcon2DesktopWebp2x],
                    jpeg: [FeatureIcon2Desktop, FeatureIcon2Desktop2x],
                },
                mobile: {
                    webp: [FeatureIcon2MobileWebp, FeatureIcon2MobileWebp2x],
                    jpeg: [FeatureIcon2Mobile, FeatureIcon2Mobile2x],
                },
                description: 'A fairytale waterfall of creativity.',
                width: 190,
                height: 203,
            },
        },
        {
            'Endless holiday every day': {
                desktop: {
                    webp: [FeatureIcon3DesktopWebp, FeatureIcon3DesktopWebp2x],
                    jpeg: [FeatureIcon3Desktop, FeatureIcon3Desktop2x],
                },
                mobile: {
                    webp: [FeatureIcon3MobileWebp, FeatureIcon3MobileWebp2x],
                    jpeg: [FeatureIcon3Mobile, FeatureIcon3Mobile2x],
                },
                description: 'A lot of different colors balloons.',
                width: 190,
                height: 203,
            },
        },
        {
            'A little magic in real life': {
                desktop: {
                    webp: [FeatureIcon4DesktopWebp, FeatureIcon4DesktopWebp2x],
                    jpeg: [FeatureIcon4Desktop, FeatureIcon4Desktop2x],
                },
                mobile: {
                    webp: [FeatureIcon4MobileWebp, FeatureIcon4MobileWebp2x],
                    jpeg: [FeatureIcon4Mobile, FeatureIcon4Mobile2x],
                },
                description: 'Magic wand to boost your creativity.',
                width: 190,
                height: 203,
            },
        },
    ],
};

export const APP_DESCRIPTION_SECTION_CONTENT = {
    title: 'Dressville - City of Fashion',
    appIcon: {
        desktop: {
            webp: [DressvilleIconDesktopWebp, DressvilleIconDesktopWebp2x],
            jpeg: [DressvilleIconDesktop, DressvilleIconDesktop2x],
        },
        mobile: {},
        description: 'Dressville App Icon',
        width: 96,
        height: 96,
    } as ImageSources,
    appDescription:
        "Forget about Paris and Milan. Welcome to the real capital of style & beauty. This is Dressville. It won't be that easy to conquer it. This is a fashion game, so you need to pump up your character, making her a star and a desirable prey for the paparazzi. Your heroine will have her own style, her own blog, her own tasks. But you have to start from scratch. Dressville is a fashion game for the most persistent fans of girls games.",
    appMobileDescription:
        'In June 2023, EA announced an internal reorganization of the company: CEO Andrew\u00A0Wilson announced a realignment of the company into two organizations – EA Sports and EA Entertainment.',
    appLinks: {
        appStore: 'https://apps.apple.com/us/app/dressville-city-of-fashion/id6475726433',
        playStore: 'https://play.google.com/store/apps/details?id=com.happyabby.dressville&hl=en',
        appGallery: 'https://appgallery.huawei.com/app/C112027087',
    },
};

export const GALLERY_CONTENT = [
    {
        desktop: {
            jpeg: [GalleryImage1, GalleryImage1],
            webp: [GalleryImage1Webp, GalleryImage1Webp2x],
        },
        mobile: {
            webp: [GalleryImage1Webp, GalleryImage1Webp2x],
        },
        description: 'A girl is going to party.',
        width: 332,
        height: 720,
    } as ImageSources,
    {
        desktop: {
            jpeg: [GalleryImage2, GalleryImage2],
            webp: [GalleryImage2Webp, GalleryImage2Webp2x],
        },
        mobile: {
            webp: [GalleryImage2Webp, GalleryImage2Webp2x],
        },
        description: 'A girl dressed in a swimsuit.',
        width: 332,
        height: 720,
    } as ImageSources,
    {
        desktop: {
            jpeg: [GalleryImage3, GalleryImage3],
            webp: [GalleryImage3Webp, GalleryImage3Webp2x],
        },
        mobile: {
            webp: [GalleryImage3Webp, GalleryImage3Webp2x],
        },
        description: 'A girl dressed in a beatiful pink dress.',
        width: 332,
        height: 720,
    } as ImageSources,
    {
        desktop: {
            jpeg: [GalleryImage4, GalleryImage4],
            webp: [GalleryImage4Webp, GalleryImage4Webp2x],
        },
        mobile: {
            webp: [GalleryImage4Webp, GalleryImage4Webp2x],
        },
        description: 'A women dressed in a beatiful violet dress.',
        width: 332,
        height: 720,
    } as ImageSources,
    {
        desktop: {
            jpeg: [GalleryImage5, GalleryImage5],
            webp: [GalleryImage5Webp, GalleryImage5Webp2x],
        },
        mobile: {
            webp: [GalleryImage5Webp, GalleryImage5Webp2x],
        },
        description: 'A girl is watching for a look that fits well.',
        width: 332,
        height: 720,
    } as ImageSources,
    {
        desktop: {
            jpeg: [GalleryImage6, GalleryImage6],
            webp: [GalleryImage6Webp, GalleryImage6Webp2x],
        },
        mobile: {
            webp: [GalleryImage6Webp, GalleryImage6Webp2x],
        },
        description: 'Couple of girls.',
        width: 332,
        height: 720,
    } as ImageSources,
];

export const MOBILE_GALLERY_CONTENT = [...GALLERY_CONTENT.slice(3), ...GALLERY_CONTENT.slice(0, 3)];

export const TESTIMONIALS_SECTION_CONTENT = {
    title: 'What others say',
    testimonials: [
        {
            title: 'Great to take your mind off',
            name: 'Emmy',
            review: 'Helps to relax and forget about the daily routine. I play between lessons to get a little distracted. Bright and stylish!',
            country: 'United Kingdom',
            starsCount: 5,
        },
        {
            title: 'Useful',
            name: 'Ha Yun',
            review: 'When I grow up I will be part of the fashion world, I want to work in the fashion industry, but for now I play such games to learn how to combine outfits and colours.',
            country: 'South Korea',
            starsCount: 5,
        },
        {
            title: 'Suitable for both mothers and daughters',
            name: 'Věrča',
            review: 'I like the style of the game and the many options for choosing the character\'s clothes. My 10-year-old daughter got me hooked on the game. Now we have a kind of competition named "who will make the coolest look".',
            country: 'Czech Republic',
            starsCount: 5,
        },
        {
            title: 'For all fans of fashion games',
            name: 'Lana',
            review: "I'm a big fan of fashion and style games, I download them in batches. This game is one of my recent finds. I like everything so far. I only hope that the choice of outfits will become even larger over time.",
            country: 'USA',
            starsCount: 4,
        },
        {
            title: 'More quests please',
            name: 'Kira',
            review: "Hey guys, I really like how everything is drawn! I've been looking for a dress up game for a long time that's not for little kids. I liked this game. But let's make the tasks more difficult already.",
            country: 'Germany',
            starsCount: 4,
        },
    ],
};

export const SLIDER_CONTENT = [
    GalleryImage1,
    GalleryImage2,
    GalleryImage3,
    GalleryImage4,
    GalleryImage5,
    GalleryImage6,
];

export const ABOUT_SECTION_CONTENT =
    "In the bustling heart of a modern tech city, a dedicated team of creative developers has found their niche in the world of fashion gaming. We are eclectic group-consisting of talented designers, programmers and artists, that come together to create immersive and engaging fashion games tailored specifically for girls. With our collective passion for style and gaming, we aim to inspire creativity and confidence in their players through interactive experiences. Each member brings a unique skill set to the table—some have backgrounds in fashion design, while others are seasoned game developers. To keep the experience fresh and relevant, Happy Abby team regularly conduct research on current fashion trends and integrate feedback from our player community. This approach creates a dynamic interaction between the developers and our audience, making players feel valued and involved in the game's evolution. Moreover, the team understands the importance of representation and inclusivity in gaming. We strive to create diverse characters with varying body types, skin tones, and fashion choices. By doing so, they aim to empower girls from all walks of life, encouraging them to embrace their unique styles and celebrate their individuality through virtual expression. We’re not just creating games; we’re crafting experiences that spark joy, creativity, and a sense of belonging, all while making the world of fashion accessible and fun for girls everywhere.";

export const CONTACTS_SECTION_CONTENT = {
    country: 'Feel free to contact us',
    email: 'support@happyabby.fun',
};

export const FOOTER_SECTION_CONTENT = {
    links: [{ [PRIVACY_PAGE_NAME]: PRIVACY_PAGE_LINK }, { [TERMS_PAGE_NAME]: TERMS_PAGE_LINK }],
    copyright: '© 2024 Happy Abby. \nAll rights reserved',
};

export const PRIVACY_POLICY_PAGE_CONTENT: PageContent = {
    'Privacy Policy': [
        ['Version effective as of December 20, 2023.'],
        [
            [
                'This document defines the policy the Right Holder in relation to the processing of personal data of the users of the website ',
                { DOMAIN },
                ' (hereinafter - the Site), and the mobile applications of the Right Holder (hereinafter - the Policy).',
            ],
            'The User agrees to the terms of this Policy when using the Service or submitting their Personal Data to the Right Holder.',
        ],
        [
            'Terms shall mean:',
            'Personal Data - A set of private data and/or non-personally identifiable information about a User.',
            'User - a natural person who uses the Site, has downloaded the Mobile Application on a smartphone, tablet or other device.',
            'Service - Site and Mobile Application.',
            "Mobile Application - the Right Holder's software with all enhancements and additions.",
            'Processing of Personal Data (Processing) - any action (operation) or a set of actions (operations) with Personal Data performed with or without the use of automation tools. Processing includes, but is not limited to: collection, recording, systematization, accumulation, storage, clarification (updating, modification), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction.',
            'Automated Processing - processing of Personal Data by means of computer technology.',
            'Distribution - actions aimed at disclosure of Personal Data to an indefinite number of persons.',
            'Provision - actions aimed at disclosure of Personal Data to a certain person (circle of persons).',
            'Blocking - temporary termination of processing of Personal Data (unless the processing is necessary for their clarification).',
            'Destruction - actions as a result of which it becomes impossible to restore the content of Personal Data and (or) as a result of which tangible media of Personal Data are destroyed.',
            'Depersonalization - actions, as a result of which it becomes impossible, without the use of additional information, to determine whether Personal Data belongs to a particular User.',
            'Cross-border transfer of Personal Data - transfer of Personal Data to a foreign country, a foreign authority, a foreign individual or a foreign legal entity.',
        ],
    ],
    '1. Principles of Processing': [
        [
            '1.1. Processing of Personal Data is carried out in accordance with the principles:',
            '1.1.1. The lawfulness and fairness of the Processing.',
            '1.1.2. Processing in accordance with specific, predetermined and legitimate purposes.',
            '1.1.3. Avoidance of combining databases containing Personal Data whose Processing is incompatible with each other.',
            '1.1.4. Processing only those Personal Data that meet the purposes of their Processing.',
            '1.1.5. Compliance of the content and scope of Personal Data with the stated purposes of Processing.',
            '1.1.6. Accuracy, sufficiency, relevance and reliability of Personal Data.',
            '1.1.7. Legitimacy of technical measures aimed at Processing.',
            '1.1.8. Reasonableness and expediency of Processing.',
            '1.1.9. Storage of Personal Data in a form that identifies the subject for no longer than the purpose of the Processing, or for the duration of the Consent period.',
            '1.1.10. Destruction or depersonalization of processed Personal Data in cases specified in this Policy.',
        ],
    ],
    '2. Purposes of Processing': [
        [
            '2.1. The Right Holder shall collect and process Personal Data in order:',
            '2.1.1. To improve the operation and technical support of the Service.',
            '2.1.2. To comply with legal requirements.',
            '2.1.3. To communicate with the User when they send inquiries.',
            '2.1.4. To fulfill obligations to the User when they use the Service.',
            '2.1.5. To conduct statistical research.',
        ],
    ],
    '3. List of Personal Data to be Collected': [
        [
            '3.1. The Right Holder processes Personal Data, which the User has indicated in the forms of the Site, sent by e-mail specified in the Policy, in the Service, including:',
            '3.1.1. surname, first name, patronymic;',
            '3.1.2. telephone;',
            '3.1.3. e-mail address.',
            '3.2. The Right Holder processes technical information (non-personalized information) if it can be correlated with the User:',
            "3.2.1. About the User's device: type, device model, OS version, Internet service provider.",
            '3.2.2. About the user: Country, region, city.',
            '3.2.3. About using the Mobile Application: events within the application (date, time and number of visits, information about downloaded or viewed materials, language used), application version, version of libraries used, information about failures within the application.',
            "3.2.4. About the events that occurred during the User's interaction with the Site pages.",
        ],
    ],
    '4. Cookies': [
        [
            "4.1. The Site uses minor files that are placed on the User's device (cookies) that allow information about the User's chosen language to be stored.",
            "4.2. Cookies are used to collect non-personally identifiable information about the User in order to personalize the User's display.",
            '4.3. The User has the right to disable cookies at any time using the functionality of their device.',
            '4.4. If the User disables cookies, certain functionality of the Site may become unavailable, including the language for the User will be English only.',
        ],
    ],
    '5. Terms of processing of Personal Data': [
        [
            '5.1. The legal basis for the Processing shall be:',
            '5.1.1. Fulfillment by the Right Holder of its obligations to the User when he/she uses the Service.',
            "5.1.2. User's request.",
            "5.1.3. User's consent.",
            '5.2. The Right Holder carries out Automated Processing.',
            "5.3. The Right Holder does not verify the User's legal capacity.",
        ],
    ],
    '6. Storage of Personal Data and protection measures': [
        [
            '6.1. The Right Holder processes Personal Data during the term of use of the Service.',
            '6.2. The Right Holder takes administrative, technical measures, which are necessary to protect Personal Data.',
        ],
    ],
    '7. Update, Destruction, Blocking of Personal Data': [
        [
            '7.1. In case of confirmation of the fact of inaccuracy of Personal Data, the Right Holder shall update it.',
            '7.2. The Right Holder destroys Personal Data in the case of:',
            '7.2.1. The presence of a threat to the security of the Service.',
            "7.2.2. Removal of the Mobile Application from the User's device.",
            '7.2.3. Achievement of the goal of Processing or loss of the need to achieve such goals.',
            '7.2.4. Withdrawal of Consent.',
            '7.3. The Right Holder has the right to temporarily discontinue Processing (except if the Processing is necessary to clarify Personal Data).',
        ],
    ],
    '8. Transfer of Personal Data': [
        [
            '8.1. The Right Holder shall not provide Personal Data to third parties without the consent of the User, except for the following case:',
            '8.1.1. Provision is necessary to stop illegal actions of the User.',
            '8.1.2. To protect the legitimate interests of the Right Holder or third parties.',
            '8.1.3. As established by law.',
            "8.1.4. Receiving a binding request from a judicial authority, prosecutor's office, police, investigative body.",
            '8.2. The Right Holder has the right to provide Personal Data to third parties for processing in order to fulfill obligations to the User.',
            '8.3. The Right Holder uses in the Service the analytical platforms specified in Annex No. 1 to the Policy.',
            '8.4. In case of providing Personal Data, the Operator warns the recipients that this data can be used only for the purposes for which it is communicated.',
        ],
    ],
    '9. Cross-border Transfer of Personal Data': [
        [
            '9.1. In order to achieve the goals specified in the Policy, the Right Holder may transfer Personal Data to countries other than the country from which it was originally received.',
            "9.2. Prior to cross-border transfer of Personal Data, the Right Holder shall ensure that the foreign country to whose territory Personal Data is transferred provides adequate protection of the User's rights.",
            '9.3. In the case of cross-border transfer of Personal Data, the Right Holder shall protect the data in accordance with the Policy and the law.',
        ],
    ],
    '10. Rights of the User. Requests to the Right Holder': [
        [
            '10.1. The User has the right to submit a request for clarification or correction of his/her Personal Data or the processing of his/her Personal Data, including:',
            '10.1.1. The legal basis for processing.',
            '10.1.2. The purposes and methods of processing used.',
            '10.1.3. The Personal Data processed, related to the User concerned, the source of their receipt.',
            '10.1.4. The terms of processing, including the terms of storage of Personal Data.',
            '10.1.5. The procedure for exercising the rights provided for by the legislation.',
            '10.1.6. The performed or expected cross-border transfer of data.',
            '10.1.7. Persons to whom Personal Data may be disclosed on the basis of an agreement with the Right Holder, or data of a person performing the processing on behalf of the Right Holder, if the processing is or is intended to be assigned to such a person.',
            ["10.2. Right Holder's email ", { copyrightEmail }, ' for submitting a request.'],
            '10.3. The User must specify in the request the information that allows to identify the User.',
        ],
    ],
    '11. Obligations of the Right Holder': [
        [
            "11.1. Within 30 (thirty) days of receipt of the User's request, the Right Holder is obligated to consider the request and send a reply or reasonable refusal.",
            "11.2. At the User's request, the Right Holder clarifies, blocks or deletes the processed Personal Data if it is incomplete, outdated, inaccurate, illegally obtained or unnecessary for the stated purpose of the Processing.",
            '11.3. The Right Holder shall ensure the lawfulness of the Processing. If it is impossible to ensure the legitimacy of Processing, the Right Holder is obliged to destroy or ensure the Destruction of Personal Data within 10 (ten) business days from the date of detection of unlawful Processing.',
        ],
    ],
    '12. Closing Provisions': [
        [
            [
                '12.1. Availability of the text of the Policy for viewing: The Policy is available for viewing at ',
                { privacyPageUrl },
                '.',
            ],
            '12.2. Limitations of the Policy: The Service, the Policy may contain links to other resources that are not managed by the Right Holder. If the User follows the link he/she is redirected to a third party resource. The Right Holder recommends to read the terms of processing of personal data of third-party resources. The Right Holder is not responsible for the content of third-party resources and their policies regarding the processing of personal data.',
            '12.3. Age restrictions: The Service is intended for a wide audience, if the Mobile application contains age restrictions, the User who does not comply with them shall not use the product. The legal representative of the User whose age did not allow the use of the Mobile Application shall have the right to send a request to the Right Holder to delete the Personal Data of the person being represented.',
            [
                '12.4. Amendments: The Right Holder shall be entitled to make amendments to the Policy as necessary. The current edition shall include the date of the last update. The new version of the Policy shall be effective from the moment of its posting in the public domain at: ',
                { privacyPageUrl },
                '.',
            ],
        ],
        [
            ['E-mail address for matters of the Privacy Policy: ', { privacyEmail }],
            ['E-mail address for other matters: ', { suppportEmail }],
        ],
    ],
    'Annex 1': [
        ['to the Privacy Policy as of December 20, 2023.'],
        ['Version of Annex 1 effective as of December 20, 2023.'],
        [
            'Analytical platforms:',
            [
                '1. Google Analytics. The number of visits to the site, the sources from which the transition to the site was made. (Privacy Policy: ',
                { googlePrivacyUrl },
                ').',
            ],
            [
                '2. Amplitude. The User actions in the Application and the operation of the Application. (Privacy Policy: ',
                { ampllitudePrivacyUrl },
                ').',
            ],
            ['3. Yandex.Metrica counter (Privacy Policy: ', { metrikaPricayPolicy }, ').'],
        ],
    ],
};

export const TERMS_OF_USE_CONTENT: PageContent = {
    'End User License Agreement': [
        ['Version No. 1 dated 30.04.2021.'],
        [
            'In this license agreement (the "Agreement"), capitalized terms mean:',
            [
                "Application - any application that belongs to the Right Holder. The list of Applications is available on the Right Holder's website ",
                { DOMAIN },
                '.',
            ],
            'User - the person who uses the Application.',
            'App Store - Apple Platform (owned by Apple, Inc.), through which the User may access or install the Application on his or her Device.',
            'Device - authorized smartphone, tablet, personal computer or other device that the User uses legally.',
            'Subscription - the amount of rights granted to the User for a fee.',
            'Fee - the price of the Subscription for a certain period.',
            'Card - a bank card, the details of which are specified by the User in the Account for purchases in the App Store.',
            "Account - User's App Store account, which is required to access the functionality of the Application.",
            'Content - text, links, software, audio, graphic or video images, tags, or other materials or information.',
            [
                "Policy - The rules governing the collection, processing and protection of the User's personal data during the use of the Application. Available at: ",
                { privacyPageUrl },
                '.',
            ],
        ],
    ],
    '1. General provisions': [
        [
            '1.1. The Right Holder grants the User a non-exclusive, personal (non-transferable) license to download the Application onto the Device and use it in accordance with the terms of the Agreement.',
            '1.2. By downloading, accessing or using the Application, the User agrees to the terms of the Agreement and the Policy.',
            '1.3. If the User does not agree with any of the terms of the Agreement or the Policy, he shall not download or use the Application.',
            "1.4. By downloading the Application, the User confirms that in accordance with the laws of the country of his/her location the age of the User allows him/her to use the Application under the terms of the Agreement and the Policy. If the User's age does not allow him/her to accept the terms of the Agreement and the Policy, he/she guarantees that the legal representative has read and agrees with the Agreement and the Policy and allows to use the Application.",
        ],
    ],
    '2. Subscription': [
        [
            '2.1. The Right Holder has the right to provide access to the Application or its individual functionality via Subscription.',
            '2.2. The Right Holder is entitled to provide a trial period of the functionality available under the Subscription for the period specified in the Application.',
            '2.3. Subscription period depends on the Subscription Fee paid by the User.',
            '2.4. The Fee is listed in the Application. The Fee does not include possible commissions of payment providers.',
            '2.5. The Right Holder has the right at its own discretion and unilaterally to set discounts on the Fee, to change it, and the fee paid for the Subscription is not subject to change. The Right Holder notifies about changes in the Fee by posting information in the Application.',
            '2.6. If the App Store has returned the payment for the Subscription to the User, the Right Holder shall block access to the functionality under the Subscription.',
            '2.7. Information about the activated Fee and the number of remaining days of use is contained in the Account.',
        ],
    ],
    '3. Payments': [
        [
            '3.1. The Fee selected by the User is debited from the Card when the User confirms the purchase, and then automatically.',
            '3.2. Subscription renewal Fees shall be charged from the Card until the end of the current Subscription period or at the end of the Subscription period, depending on the App Store approach regarding the original Subscription Fee.',
            '3.3. User may manage Subscriptions and disable their automatic renewal by means of the Account.',
            '3.3.1. The User has the right to cancel Subscriptions during the trial period. If the User fails to cancel the Subscription before the end of the trial period within the period set by the App Store, the payment for the Subscription will be charged from the Card.',
            '3.3.2. If the User cancels a Subscription, the cancellation shall be effective after the end of the last day of the Subscription period. If the User has not canceled the Subscription for the next period, the Fee will be charged from the Card.',
            "3.4. User's payment for the Fee shall be processed through the App Store interface.",
            '3.5. If the Card is not attached to the Account, its details are invalid or there are not enough funds to pay for the Subscription, the Right Holder does not provide access to the Subscription.',
            '3.6. No refunds shall be made for the paid but unused Subscription period.',
        ],
    ],
    '4. Restrictions': [
        [
            '4.1. The User shall not:',
            '4.1.1. sell, distribute, sublicense, assign, or provide simultaneous access on multiple Devices, unless expressly permitted in a particular Application by the Right Holder and specified in the App Store.',
            '4.1.2. use the Application in ways not stipulated by the Agreement;',
            '4.1.3. try to evade the technical limitations set by the Application;',
            '4.1.4. use third-party programs or other means to improve/automate the features of the Application;',
            '4.1.5. use the Application for illegal activities;',
            '4.1.6. decompile, disassemble, reverse engineer, or create derivative works from the Application or parts thereof.',
            '4.2. The User can access the Application from Devices with certain characteristics specified in the description of the Application.',
            '4.3. The Application may contain restrictions on the number of available languages.',
            '4.4. The Right Holder may restrict the use of certain features of the Application for all or certain Users.',
            '4.5. The Application or certain functionality may not be available to the User at certain times or in certain territories, including for technical reasons.',
        ],
    ],
    '5. Liability': [
        [
            '5.1. The Application is provided "as is". Right Holder does not guarantee that the Application meets the User\'s expectations, and is be provided continuously, quickly, reliably and error-free.',
            '5.2. The User carries the responsibility for:',
            '5.2.1. relevance, reliability, absence of third-party claims in relation to the provided Content;',
            '5.2.2. compliance with the laws of the country of location when using the Application. The User shall comply with the restrictions on the use of the Application, which are imposed on it by the law of the country of its location.',
            '5.3. Right holder shall not be liable for:',
            '5.3.1. specialized resources and quality of channels of public communication networks, through which the access to the Application is provided, as well as caused by interruptions in the provision of services by contractors;',
            '5.3.2. breakdowns or other malfunctions of the Device, which occurred during the use of the Application;',
            '5.3.3. consequences caused by the loss or disclosure by the User of his data required to access the Application;',
            '5.3.4. errors and/or violations related to the use of the Application and arising because of unlawful actions of third parties;',
            '5.3.5. failures and interruptions in the operation of the Application caused by force majeure. Such circumstances must be confirmed by certificates from the competent authorities not later than ten (10) working days after their commencement;',
            '5.3.6. failures and interruptions in the operation of the Application caused by actions of government authorities, including law enforcement authorities, relating to seizure and/or confiscation, or otherwise preventing access to the servers of contractors hosting the Application;',
            '5.3.7. actions and payment terms set forth in the App Store.',
            '5.4. The liability of the Right Holder shall not exceed the amount of payment transferred by the User.',
        ],
    ],
    '6. Disputes': [
        [
            '6.1. The Parties shall comply with the pre-trial (claim) procedure for dispute resolution.',
            '6.2. The deadline for replying to the claim is 10 (ten) business days from the date of its receipt by the recipient Party.',
            '6.3. If the dispute cannot be resolved through the claim procedure, the dispute shall be referred to court.',
        ],
    ],
    '7. Updates': [
        [
            '7.1. The Right Holder shall not be obliged to provide the User with new versions of the Application.',
            '7.2. The Right Holder shall be entitled to add or remove any features of the Application at its sole discretion with or without prior notice.',
            '7.3. The Right Holder is entitled to send a notice to the User about the need to update the Application due to the availability of a new version or to update the Application automatically.',
            '7.4. The Right Holder is entitled not to support previous versions of the Application when updates or subsequent versions of the Application appear.',
            [
                '7.5. The Right Holder is entitled to update the Agreement at any time. The new version of the Agreement comes into force from the moment of its posting at: ',
                { termsPageUrl },
                '.',
            ],
            '7.6. The User shall check the Agreement for changes on his/her own.',
            '7.7. The User agrees to the changes in the Agreement if he/she continues to use the Application.',
        ],
    ],
    '8. Final provisions': [
        [
            "8.1. The Right Holder shall process the User's personal data in order to perform the Agreement on the basis of the Policy.",
            '8.2. The Right Holder has the right to send notices to the User under the Agreement using the contact information specified by the User or to place them in the Application.',
            '8.3. The invalidity or unenforceability of one or more provisions of the Agreement, for whatever reasons, shall not affect the validity or enforceability of the remaining provisions of the Agreement.',
            ['8.4. The current version of this Agreement is available to the User at: ', { termsPageUrl }, '.'],
        ],
    ],
};
