import React, { FC, memo, useEffect, useState } from 'react';
import cnBind from 'classnames/bind';
import { Logo } from 'assets';

import styles from './HeaderInner.module.scss';

const cx = cnBind.bind(styles);

const SCROLL_DISTANCE = 90;
const INITIAL_SCALE_VALUE = 1;

const FINAL_SCALE_VALUE = 0.65;
const FINAL_TRANSLATE_VALUE = 75;

const COEFFICIENT_OF_SCALING = 250;
const COEFFICIENT_OF_SCROLLING = 1.2;

export const HeaderInner: FC = memo(() => {
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const scaleValue =
        scrollPosition <= SCROLL_DISTANCE
            ? INITIAL_SCALE_VALUE - scrollPosition / COEFFICIENT_OF_SCALING
            : FINAL_SCALE_VALUE;
    const translateYValue =
        scrollPosition <= SCROLL_DISTANCE ? scrollPosition / COEFFICIENT_OF_SCROLLING : FINAL_TRANSLATE_VALUE;

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={cx('header')}>
            <div className={cx('header-wrapper')}>
                <a
                    className={cx('logo-wrapper')}
                    style={{
                        transform: `scale(${scaleValue}) translateY(${translateYValue}px)`,
                    }}
                    href="/"
                >
                    <Logo />
                </a>
            </div>
        </header>
    );
});
