import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { SliderType, TestimonialComponentContent } from 'types';
import { Slider } from 'components/Slider';
import { TestimonialCard } from 'components/TestimonialCard';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { TestimonialsDecorIcon } from 'assets';

import styles from './Testimonials.module.scss';

const cx = cnBind.bind(styles);

export const Testimonials: FC<{ content: TestimonialComponentContent }> = memo(({ content }) => (
    <section className={cx('testimonials')}>
        <div className={cx('title-wrapper')}>
            <Text className={cx('title')} type={TextStyles.TITLE} align="center">
                <TestimonialsDecorIcon className={cx('title-decoration')} />
                {content.title}
            </Text>
        </div>
        <div className={cx('slider-wrapper')}>
            <Slider type={SliderType.Cards}>
                {content.testimonials.map((testimonialCardData, index) => (
                    <TestimonialCard content={testimonialCardData} key={`${testimonialCardData.name}-${index}`} />
                ))}
            </Slider>
        </div>
    </section>
));
