import React, { Dispatch, memo, ReactElement, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cnBind from 'classnames/bind';
import { SliderControlType, TabsColorSchema } from 'types';
import { CrossIcon } from 'assets';
import { useStopBodyScrolling } from 'helpers';

import { PAGE_ID } from '../../../constants';
import { SliderControl } from '../SliderControl';
import { Tabs } from '../Tabs';

import styles from './SliderModal.module.scss';

const cx = cnBind.bind(styles);

const SWIPE_THRESHOLD = 50;

export const SliderModal: React.FC<{
    children: ReactElement[];
    modalSlideIndex: number;
    onPreviousButtonClick: () => void;
    onNextButtonClick: () => void;
    setModalClose: () => void;
    setModalSlideIndex: Dispatch<SetStateAction<number>>;
}> = memo(
    ({ onPreviousButtonClick, onNextButtonClick, setModalClose, modalSlideIndex, setModalSlideIndex, children }) => {
        const [pointerStartX, setPointerStartX] = useState<number>(0);
        const [isDragging, setIsDragging] = useState<boolean>(false);

        const slideAmount = children.length;
        const activeIndex = (modalSlideIndex - slideAmount / 2 + slideAmount) % slideAmount;

        useStopBodyScrolling(true);

        useEffect(() => {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    setModalClose();
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }, [setModalClose]);

        const handleCloseModal = (event: React.MouseEvent | PointerEvent) => {
            event.stopPropagation();

            setModalClose();
        };

        const handlePrevious = (event?: React.PointerEvent<HTMLButtonElement>, isOnlyModalRotation = false) => {
            if (event) {
                event.stopPropagation();
            }

            setModalSlideIndex((prevIndex) => (prevIndex - 1 < 0 ? slideAmount - 1 : prevIndex - 1));

            if (!isOnlyModalRotation) onPreviousButtonClick();
        };

        const handleNext = (event?: React.PointerEvent<HTMLButtonElement>, isOnlyModalRotation = false) => {
            if (event) {
                event.stopPropagation();
            }

            setModalSlideIndex((prevIndex) => (prevIndex + 1 >= slideAmount ? 0 : prevIndex + 1));

            if (!isOnlyModalRotation) onNextButtonClick();
        };

        const handlePointerDown = (event: React.PointerEvent) => {
            event.preventDefault();
            event.stopPropagation();

            setIsDragging(true);
            setPointerStartX(event.clientX);
        };

        const handlePointerMove = (event: React.PointerEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (isDragging) {
                const dragDistance = event.clientX - pointerStartX;
                const isSwipedLeft = dragDistance > 0;

                if (Math.abs(dragDistance) > SWIPE_THRESHOLD) {
                    isSwipedLeft ? handlePrevious() : handleNext();
                    setPointerStartX(event.clientX);
                    setIsDragging(false);
                }
            }
        };

        const handlePointerUp = (event: React.PointerEvent) => {
            event.stopPropagation();
            event.preventDefault();

            setIsDragging(false);
        };

        return ReactDOM.createPortal(
            <div className={cx('modal-wrapper')} onClick={handleCloseModal}>
                <div className={cx('modal')}>
                    <section className={cx('modal-body')}>
                        <SliderControl
                            type={SliderControlType.Previous}
                            handleClick={(e) => handlePrevious(e)}
                            className={cx('slider-control--left', 'basic-slider-control')}
                        />
                        <SliderControl
                            type={SliderControlType.Previous}
                            handleClick={(e) => handlePrevious(e, false)}
                            className={cx('slider-control--left', 'fullwidth-slider-control')}
                        />
                        <div className={cx('image-wrapper')}>
                            <button className={cx('close-icon-wrapper')} type="button" onClick={handleCloseModal}>
                                <CrossIcon className={cx('close-icon')} />
                            </button>
                            <button
                                className={cx('modal-image-button')}
                                type="button"
                                onPointerDown={handlePointerDown}
                                onPointerMove={handlePointerMove}
                                onClick={(e) => e.stopPropagation()}
                                onPointerUp={handlePointerUp}
                                onPointerCancel={handlePointerUp}
                            >
                                {children[modalSlideIndex]}
                            </button>
                        </div>
                        <SliderControl
                            type={SliderControlType.Next}
                            handleClick={handleNext}
                            className={cx('slider-control--right', 'basic-slider-control')}
                        />
                        <SliderControl
                            type={SliderControlType.Next}
                            handleClick={(e) => handleNext(e, false)}
                            className={cx('slider-control--right', 'fullwidth-slider-control')}
                        />
                    </section>
                    <footer className={cx('modal-footer')}>
                        <Tabs entities={children} activeIndex={activeIndex} colorSchema={TabsColorSchema.Grey} />
                    </footer>
                </div>
            </div>,
            document.getElementById(PAGE_ID) as HTMLElement,
        );
    },
);
