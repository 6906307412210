import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { CompanyInfoProps } from 'types';

import styles from './CompanyInfo.module.scss';

const cx = cnBind.bind(styles);

export const CompanyInfo: FC<CompanyInfoProps> = memo(({ address, email, companyDescription }) => (
    <section className={cx('company-info')}>
        <div className={cx('wrapper')}>
            <article className={cx('about-us')}>
                <Text type={TextStyles.TITLE}>About Us</Text>
                <Text className={cx('about-us-text')} type={TextStyles.PARAGRAPH}>
                    {companyDescription}
                </Text>
            </article>
            <div className={cx('contact-us')}>
                <Text type={TextStyles.TITLE}>Contacts</Text>
                <Text className={cx('contact-info')} type={TextStyles.PARAGRAPH}>
                    <Text className={cx('address')}>{address}</Text>
                    <a className={cx('email')} href={`mailto:${email}`}>
                        {email}
                    </a>
                </Text>
            </div>
        </div>
    </section>
));
