import React, { memo } from 'react';
import cnBind from 'classnames/bind';
import { Features } from 'components/Features';
import { Header } from 'components/Header';
import { AppDescription } from 'components/AppDescription';
import { CompanyInfo } from 'components/CompanyInfo';
import { Footer } from 'components/Footer';
import { Slider } from 'components/Slider';
import { SliderType } from 'types';
import { Testimonials } from 'components/Testimonials';
import { CookiePopup } from 'components/CookiePopup';
import { ResponsiveImage } from 'components/ResponsiveImage';
import { useDesktopSlider } from 'helpers';

import {
    APP_DESCRIPTION_SECTION_CONTENT,
    FEATURES_SECTION_CONTENT,
    CONTACTS_SECTION_CONTENT,
    ABOUT_SECTION_CONTENT,
    FOOTER_SECTION_CONTENT,
    TESTIMONIALS_SECTION_CONTENT,
    GALLERY_CONTENT,
    PAGE_ID,
    MOBILE_GALLERY_CONTENT,
} from '../../constants';

import styles from './HomePage.module.scss';

const cx = cnBind.bind(styles);

const { country, email } = CONTACTS_SECTION_CONTENT;
const { links, copyright } = FOOTER_SECTION_CONTENT;

export const HomePage = memo(() => {
    const isDesktopSlider = useDesktopSlider();
    const gallery = isDesktopSlider ? GALLERY_CONTENT : MOBILE_GALLERY_CONTENT;

    return (
        <div className={cx('page-wrapper')} id={PAGE_ID}>
            <Header />
            <main className={cx('main')}>
                <h1 className="visually-hidden">Dressville - City of Fashion</h1>
                <Features content={FEATURES_SECTION_CONTENT} />
                <AppDescription content={APP_DESCRIPTION_SECTION_CONTENT}>
                    <Slider type={SliderType.Gallery}>
                        {gallery.map((image, index) => (
                            <ResponsiveImage sources={image} key={`${image.description}-${index}`} />
                        ))}
                    </Slider>
                </AppDescription>
                <Testimonials content={TESTIMONIALS_SECTION_CONTENT} />
                <CompanyInfo address={country} email={email} companyDescription={ABOUT_SECTION_CONTENT} />
            </main>
            <Footer copyright={copyright} links={links} />
        </div>
    );
});
