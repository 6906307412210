import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cnBind from 'classnames/bind';
import { SliderControlType, SliderType, TabsColorSchema } from 'types';
import { getSlideIndexes, getTranslateXGalleryValue, getTranslateXCardsValue } from 'helpers';

import {
    GALLERY_SLIDE_WIDTH,
    GALLERY_SLIDES_COLUMN_GAP,
    CARDS_SLIDES_COLUMN_GAP,
    CARDS_SLIDE_WIDTH,
    CARDS_SLIDER_FULL_WIDTH,
    GALLERY_SLIDER_FULL_WIDTH,
} from '../../constants';

import { SliderControl } from './SliderControl';
import { Tabs } from './Tabs';
import styles from './Slider.module.scss';
import { SliderArea } from './SliderArea';
import { Slides } from './Slides';
import { SliderModal } from './SliderModal';

const cx = cnBind.bind(styles);

export const Slider: React.FC<{
    children: ReactElement[];
    type: SliderType;
}> = memo(({ type, children }) => {
    const sliderTrackRef = useRef<HTMLUListElement>(null);
    const sliderTrackWidth = sliderTrackRef?.current?.offsetWidth ?? 0;

    const isSwipesActive = useMemo(
        () =>
            type === SliderType.Gallery
                ? sliderTrackWidth < GALLERY_SLIDER_FULL_WIDTH
                : sliderTrackWidth < CARDS_SLIDER_FULL_WIDTH,
        [sliderTrackWidth, type],
    );

    const [isModalOpen, setModalOpen] = useState(false);

    const [timeoutInProgress, setTimeoutInProgress] = useState(false);
    const [isTransitionEnabled, setTransitionEnabled] = useState<boolean>(true);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(children.length);
    const [modalSlideIndex, setModalSlideIndex] = useState<number>(0);
    const [slideClickDisabled, setSlideClickDisabled] = useState(false);

    const {
        slideAmount,
        leftTransitionSlideIndex,
        rightTransitionSlideIndex,
        leftEdgeSlideIndex,
        rightEdgeSlideIndex,
        actualActiveSlideIndex,
    } = useMemo(() => getSlideIndexes(children, activeSlideIndex, children.length), [activeSlideIndex, children]);

    const translateXValue = useMemo(
        () =>
            type === SliderType.Gallery
                ? getTranslateXGalleryValue(
                      slideAmount,
                      activeSlideIndex,
                      GALLERY_SLIDE_WIDTH,
                      GALLERY_SLIDES_COLUMN_GAP,
                  )
                : getTranslateXCardsValue(slideAmount, activeSlideIndex, CARDS_SLIDE_WIDTH, CARDS_SLIDES_COLUMN_GAP),
        [activeSlideIndex, slideAmount, type],
    );

    const isEdgeSlide = useMemo(
        () =>
            activeSlideIndex === leftEdgeSlideIndex ||
            activeSlideIndex === leftEdgeSlideIndex + 1 ||
            activeSlideIndex === leftEdgeSlideIndex + 2 ||
            activeSlideIndex === rightEdgeSlideIndex ||
            activeSlideIndex === rightEdgeSlideIndex - 1 ||
            activeSlideIndex === rightEdgeSlideIndex - 2,
        [activeSlideIndex, leftEdgeSlideIndex, rightEdgeSlideIndex],
    );

    useEffect(() => {
        setModalSlideIndex((actualActiveSlideIndex + slideAmount / 2) % slideAmount);
    }, [actualActiveSlideIndex, slideAmount]);

    useEffect(() => {
        if (isEdgeSlide) {
            setTransitionEnabled(true);
        }
    }, [isEdgeSlide]);

    const handlePrevious = useCallback(() => {
        if (activeSlideIndex === leftTransitionSlideIndex) {
            return;
        }

        const isOnEdgeBack = activeSlideIndex <= leftEdgeSlideIndex + 1;

        if (isOnEdgeBack) {
            setTimeoutInProgress(true);
        }

        if (isSwipesActive) {
            setSlideClickDisabled(true);
        }

        setActiveSlideIndex((prevIndex) => prevIndex - 1);
    }, [activeSlideIndex, isSwipesActive, leftEdgeSlideIndex, leftTransitionSlideIndex]);

    const handleNext = useCallback(() => {
        if (activeSlideIndex === rightTransitionSlideIndex) {
            return;
        }

        const isOnEdgeForward = activeSlideIndex >= rightEdgeSlideIndex - 1;

        if (isOnEdgeForward) {
            setTimeoutInProgress(true);
        }

        if (isSwipesActive) {
            setSlideClickDisabled(true);
        }

        setActiveSlideIndex((prevIndex) => prevIndex + 1);
    }, [activeSlideIndex, isSwipesActive, rightEdgeSlideIndex, rightTransitionSlideIndex]);

    const handleTransitionEnd = useCallback(() => {
        if (activeSlideIndex === leftTransitionSlideIndex) {
            setTransitionEnabled(false);
            setActiveSlideIndex(rightEdgeSlideIndex);
        } else if (activeSlideIndex === leftTransitionSlideIndex - 1) {
            setTransitionEnabled(false);
            setActiveSlideIndex(rightEdgeSlideIndex - 1);
        } else if (activeSlideIndex === leftTransitionSlideIndex - 2) {
            setTransitionEnabled(false);
            setActiveSlideIndex(rightEdgeSlideIndex - 2);
        } else if (activeSlideIndex === rightTransitionSlideIndex) {
            setTransitionEnabled(false);
            setActiveSlideIndex(leftEdgeSlideIndex);
        } else if (activeSlideIndex === rightTransitionSlideIndex + 1) {
            setTransitionEnabled(false);
            setActiveSlideIndex(leftEdgeSlideIndex + 1);
        } else if (activeSlideIndex === rightTransitionSlideIndex + 2) {
            setTransitionEnabled(false);
            setActiveSlideIndex(leftEdgeSlideIndex + 2);
        }

        setSlideClickDisabled(false);
        setTimeoutInProgress(false);
    }, [
        activeSlideIndex,
        leftEdgeSlideIndex,
        leftTransitionSlideIndex,
        rightEdgeSlideIndex,
        rightTransitionSlideIndex,
    ]);

    const handleSlideClick = useCallback(
        (slideIndex: number) => {
            if (slideClickDisabled) {
                return;
            }

            setModalOpen(true);
            setActiveSlideIndex(slideIndex - slideAmount / 2);
        },
        [slideAmount, slideClickDisabled],
    );

    const handleModalClose = useCallback(() => setModalOpen(false), []);

    return (
        <>
            <div className={cx('slider')}>
                <SliderArea onSwipeLeft={handlePrevious} onSwipeRight={handleNext}>
                    <ul
                        className={cx(
                            'slider-track',
                            type === SliderType.Cards ? cx('cancel-translate-cards') : cx('cancel-translate-gallery'),
                        )}
                        style={{
                            transform: translateXValue,
                            transition: isTransitionEnabled ? undefined : 'none',
                            columnGap:
                                type === SliderType.Gallery ? GALLERY_SLIDES_COLUMN_GAP : CARDS_SLIDES_COLUMN_GAP,
                        }}
                        onTransitionEnd={handleTransitionEnd}
                        ref={sliderTrackRef}
                    >
                        <Slides
                            onSlideClick={type === SliderType.Cards ? undefined : handleSlideClick}
                            isTransitionEnabled={isTransitionEnabled}
                            additionalElementsCount={slideAmount}
                            isCards={type === SliderType.Cards}
                        >
                            {children}
                        </Slides>
                    </ul>
                    <SliderControl
                        type={SliderControlType.Previous}
                        handleClick={handlePrevious}
                        isDisabled={timeoutInProgress}
                        className={cx(
                            type === SliderType.Cards && 'testimonials-control',
                            type === SliderType.Cards ? 'hide-for-cards' : 'hide-for-gallery',
                        )}
                    />
                    <SliderControl
                        type={SliderControlType.Next}
                        handleClick={handleNext}
                        isDisabled={timeoutInProgress}
                        className={cx(
                            type === SliderType.Cards && 'testimonials-control',
                            type === SliderType.Cards ? 'hide-for-cards' : 'hide-for-gallery',
                        )}
                    />
                </SliderArea>
                <Tabs
                    entities={children}
                    activeIndex={actualActiveSlideIndex}
                    colorSchema={type === SliderType.Cards ? TabsColorSchema.DarkBlue : TabsColorSchema.LightBlue}
                    className={cx(
                        'slider-tabs',
                        type === SliderType.Cards ? cx('hide-for-cards') : cx('hide-for-gallery'),
                    )}
                />
            </div>
            {isModalOpen && type === SliderType.Gallery && (
                <SliderModal
                    modalSlideIndex={modalSlideIndex}
                    setModalSlideIndex={setModalSlideIndex}
                    onPreviousButtonClick={handlePrevious}
                    onNextButtonClick={handleNext}
                    setModalClose={handleModalClose}
                >
                    {children}
                </SliderModal>
            )}
        </>
    );
});
