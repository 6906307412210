import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { Footer } from 'components/Footer';
import { Text } from 'components/Text';
import { HeaderInner } from 'components/HeaderInner';
import { PageContent } from 'types';

import { FOOTER_SECTION_CONTENT } from '../../constants';

import styles from './LegalPage.module.scss';

const cx = cnBind.bind(styles);

const { links, copyright } = FOOTER_SECTION_CONTENT;

export const LegalPage: FC<{ content: PageContent }> = memo(({ content }) => (
    <div className={cx('page-wrapper')}>
        <HeaderInner />
        <main className={cx('main')}>
            <div className={cx('main-wrapper')}>
                {Object.entries(content).map(([title, paragraphs], index) => (
                    <>
                        {index === 0 ? (
                            <h1 className={cx('title')}>{title}</h1>
                        ) : (
                            <Text className={cx('subtitle')}>{title}</Text>
                        )}
                        {paragraphs.map((paragraph) => (
                            <div className={cx('paragraph')}>
                                {paragraph.map((lines) => {
                                    if (typeof lines === 'string') return <p className={cx('line')}>{lines}</p>;

                                    return (
                                        <p className={cx('line')}>
                                            {lines.map((line) => {
                                                if (typeof line === 'string') return line;
                                                const [entry] = Object.entries(line);
                                                const [, link] = entry;

                                                const href = link.includes('@') ? `mailto:${link}` : link;

                                                return (
                                                    <a className={cx('link')} href={href}>
                                                        {link}
                                                    </a>
                                                );
                                            })}
                                        </p>
                                    );
                                })}
                            </div>
                        ))}
                    </>
                ))}
            </div>
        </main>
        <Footer copyright={copyright} links={links} />
    </div>
));
