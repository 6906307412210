import { APP_DESCRIPTION_SECTION_CONTENT, FEATURES_SECTION_CONTENT, TESTIMONIALS_SECTION_CONTENT } from './constants';

export enum TabsColorSchema {
    DarkBlue = 'DARK_BLUE',
    LightBlue = 'LIGHT_BLUE',
    Grey = 'GREY',
}

export enum SliderType {
    Gallery = 'GALLERY',
    Cards = 'CARDS',
}

export enum SliderControlType {
    Previous = 'PREVIOUS',
    Next = 'NEXT',
}

export enum Breakpoint {
    Mobile = 375,
    Desktop = 960,
}

type ImageSet = [string, string]; // NOTE: Image quality order - 1x, 2x

export type ImageSources = {
    desktop: {
        webp?: ImageSet;
        jpeg: ImageSet;
    };
    mobile: {
        webp?: ImageSet;
        jpeg?: ImageSet;
    };
    description: string;
    width?: number;
    height?: number;
};

export type ParagraphsText = Array<Array<string | Array<string | { [linkText: string]: string }>>>;

export interface PageContent {
    [sectionTitle: string]: ParagraphsText;
}

export type FeaturesComponentContent = typeof FEATURES_SECTION_CONTENT;
export type AppDescriptionComponentContent = typeof APP_DESCRIPTION_SECTION_CONTENT;
export type TestimonialComponentContent = typeof TESTIMONIALS_SECTION_CONTENT;
export type TestimonialCardComponentContent = typeof TESTIMONIALS_SECTION_CONTENT.testimonials[0] & { title?: string };
export type CompanyInfoProps = { address: string; email: string; companyDescription: string };
