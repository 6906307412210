import React, { memo } from 'react';
import cnBind from 'classnames/bind';
import { Footer } from 'components/Footer';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { Illustration404 } from 'assets';

import { FOOTER_SECTION_CONTENT } from '../../constants';

import styles from './404Page.module.scss';

const cx = cnBind.bind(styles);

const { links, copyright } = FOOTER_SECTION_CONTENT;

export const UnknownPage = memo(() => {
    return (
        <div className={cx('page-wrapper')}>
            <main className={cx('main')}>
                <div className={cx('main-wrapper')}>
                    <h1 className="visually-hidden">Page does not exist</h1>
                    <Illustration404 />
                    <div className={cx('main-content')}>
                        <Text className={cx('text')} type={TextStyles.PARAGRAPH} align="center">
                            Something went wrong, please try again
                        </Text>
                        <a className={cx('link')} href="/">
                            Go to home page
                        </a>
                    </div>
                </div>
            </main>
            <Footer copyright={copyright} links={links} />
        </div>
    );
});
