import React, { FC, memo } from 'react';
import cnBind from 'classnames/bind';
import { AppDescriptionComponentContent } from 'types';
import { Text } from 'components/Text';
import { TextStyles } from 'components/Text/Text.types';
import { ResponsiveImage } from 'components/ResponsiveImage';
import { AppGalleryBadge, AppStoreBadge, DressvilleLogo, PlayStoreBadge } from 'assets';

import styles from './AppDescription.module.scss';

const cx = cnBind.bind(styles);

export const AppDescription: FC<{ content: AppDescriptionComponentContent }> = memo(({ content, children }) => (
    <section className={cx('app-description')}>
        <div className={cx('section-wrapper')}>
            <div className={cx('app-description-info')}>
                <div className={cx('logo')}>
                    <p className={cx('icon-wrapper')}>
                        <ResponsiveImage sources={content.appIcon} />
                    </p>
                    <p className={cx('logo-wrapper')}>
                        <DressvilleLogo />
                    </p>
                </div>
                <Text type={TextStyles.PARAGRAPH} className={cx('app-description-text')}>
                    {content.appDescription}
                </Text>
            </div>
            <div className={cx('slider-wrapper')}>{children}</div>
            <div className={cx('app-links-list-wrapper')}>
                <ul className={cx('app-links-list')}>
                    <a
                        className={cx('app-button-link')}
                        href={content.appLinks.appStore}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <AppStoreBadge />
                    </a>
                    <a
                        className={cx('app-button-link')}
                        href={content.appLinks.appGallery}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <AppGalleryBadge />
                    </a>
                </ul>
            </div>
        </div>
    </section>
));
