import React, { memo, PointerEvent, useEffect, useRef, useState } from 'react';
import cnBind from 'classnames/bind';

import styles from './SliderArea.module.scss';

const cx = cnBind.bind(styles);
const SWIPE_THRESHOLD = 25;

export const SliderArea: React.FC<{
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
    className?: string;
}> = memo(({ onSwipeLeft, onSwipeRight, className, children }) => {
    const sliderAreaRef = useRef<HTMLDivElement>(null);
    const swipeRef = useRef(false);

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [pointerStartX, setPointerStartX] = useState<number>(0);

    useEffect(() => {
        const element = sliderAreaRef.current;

        const handleTouchEvent = (event: TouchEvent) => {
            if (isDragging) {
                const dragDistance = event.touches[0].clientX - pointerStartX;

                if (Math.abs(dragDistance) > SWIPE_THRESHOLD) {
                    swipeRef.current = true;

                    if (dragDistance > 0) {
                        onSwipeLeft();
                    } else {
                        onSwipeRight();
                    }
                    setPointerStartX(event.touches[0].clientX);
                    setIsDragging(false);
                }
            }
        };
        const handleEventMove = (event: PointerEvent | MouseEvent) => {
            if (isDragging) {
                const dragDistance = event.clientX - pointerStartX;

                if (Math.abs(dragDistance) > SWIPE_THRESHOLD) {
                    swipeRef.current = true;

                    if (dragDistance > 0) {
                        onSwipeLeft();
                    } else {
                        onSwipeRight();
                    }
                    setPointerStartX(event.clientX);
                    setIsDragging(false);
                }
            }
        };

        const handleEventDown = (event: PointerEvent | MouseEvent) => {
            event.preventDefault();

            setIsDragging(true);
            setPointerStartX(event.clientX);
        };

        const handleEventUp = (event: PointerEvent | MouseEvent) => {
            if (swipeRef.current) {
                event.stopPropagation();
                swipeRef.current = false;
            }

            setIsDragging(false);
            swipeRef.current = false;
        };

        if (element) {
            element.addEventListener('pointerup', handleEventUp, true);
            element.addEventListener('pointerdown', handleEventDown);
            element.addEventListener('pointermove', handleEventMove);
            element.addEventListener('pointercancel', handleEventUp);

            element.addEventListener('mouseup', handleEventUp, true);
            element.addEventListener('mousedown', handleEventDown);
            element.addEventListener('mousemove', handleEventMove);
            element.addEventListener('mouseleave', handleEventUp);

            element.addEventListener('touchmove', handleTouchEvent, { passive: false });
        }

        return () => {
            if (element) {
                element.removeEventListener('pointerup', handleEventUp);
                element.removeEventListener('pointerdown', handleEventDown);
                element.removeEventListener('pointermove', handleEventMove);
                element.removeEventListener('pointercancel', handleEventUp);

                element.removeEventListener('mouseup', handleEventUp);
                element.removeEventListener('mousedown', handleEventDown);
                element.removeEventListener('mousemove', handleEventMove);
                element.removeEventListener('mouseleave', handleEventUp);

                element.removeEventListener('touchmove', handleTouchEvent);
            }
        };
    }, [isDragging, pointerStartX, onSwipeLeft, onSwipeRight]);

    return (
        <div ref={sliderAreaRef} className={cx('slider-area', className)}>
            {children}
        </div>
    );
});
