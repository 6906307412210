import React, { memo } from 'react';
import cnBind from 'classnames/bind';

import { TextComponentProps, TextStyles } from './Text.types';
import styles from './Text.module.scss';

const cx = cnBind.bind(styles);

export const Text = memo(({ children, className, type, color, align }: TextComponentProps) => {
    const styles = { color, textAlign: align };

    switch (type) {
        case TextStyles.TITLE:
            return (
                <h2 className={cx('title', className)} style={styles}>
                    {children}
                </h2>
            );
        case TextStyles.SUBTITLE:
            return (
                <h3 className={cx('subtitle', className)} style={styles}>
                    {children}
                </h3>
            );
        case TextStyles.PARAGRAPH:
            return (
                <p className={cx('paragraph', className)} style={styles}>
                    {children}
                </p>
            );
        default:
            return (
                <span className={cx(className)} style={styles}>
                    {children}
                </span>
            );
    }
});
