import React, { memo } from 'react';
import cnBind from 'classnames/bind';
import { Breakpoint } from 'types';

import styles from './ResponsiveImage.module.scss';
import { ResponsiveImageProps } from './ResponsiveImage.types';

const cx = cnBind.bind(styles);

const MIN_IMAGE_SET = 2;

export const ResponsiveImage: React.FC<ResponsiveImageProps> = memo(({ sources, className }) => {
    const { desktop, mobile } = sources;

    const hasDesktopWebpImages = Boolean(Array.isArray(desktop?.webp) && desktop?.webp.length === MIN_IMAGE_SET);
    const hasMobileWebpImages = Boolean(Array.isArray(mobile?.webp) && mobile?.webp.length === MIN_IMAGE_SET);
    const hasMobileJpegImages = Boolean(Array.isArray(mobile?.jpeg) && mobile?.jpeg.length === MIN_IMAGE_SET);

    return (
        <picture>
            {/* SECTION: Desktop assets */}
            {hasDesktopWebpImages && (
                <source
                    type="image/webp"
                    media={`(min-width: ${Breakpoint.Desktop}px)`}
                    srcSet={`${desktop?.webp && desktop?.webp[0]} 1x, ${desktop?.webp && desktop?.webp[1]}  2x`}
                />
            )}
            <source
                type="image/jpeg"
                media={`(min-width: ${Breakpoint.Desktop}px)`}
                srcSet={`${desktop.jpeg[0]} 1x, ${desktop.jpeg[1]} 2x`}
            />

            {/* SECTION: Mobile assets */}
            {hasMobileWebpImages && (
                <source
                    type="image/webp"
                    media={`(max-width: ${Breakpoint.Desktop}px)`}
                    srcSet={`${mobile?.webp && mobile?.webp[0]} 1x, ${mobile?.webp && mobile?.webp[1]} 2x`}
                />
            )}
            {hasMobileJpegImages && (
                <source
                    type="image/webp"
                    media={`(max-width: ${Breakpoint.Desktop}px)`}
                    srcSet={`${mobile?.jpeg && mobile?.jpeg[0]} 1x, ${mobile?.jpeg && mobile?.jpeg[1]} 2x`}
                />
            )}

            {/* SECTION: Default Image */}
            <img
                className={cx('image', className)}
                width={sources?.width}
                height={sources?.height}
                src={desktop.jpeg[1] ?? desktop.jpeg[0]}
                alt={sources.description}
            />
        </picture>
    );
});

export default ResponsiveImage;
