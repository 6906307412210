import { useEffect, useState } from 'react';

const SLIDER_FULL_WIDTH = 1280;

// SECTION: Hooks
export const useStopBodyScrolling = (withBodyTouchBlocked = false) => {
    useEffect(() => {
        const preventScroll = (event: TouchEvent) => {
            event.preventDefault();
        };

        document.body.classList.add('modal-open');

        if (withBodyTouchBlocked) document.addEventListener('touchmove', preventScroll, { passive: false });

        return () => {
            document.body.classList.remove('modal-open');
            document.removeEventListener('touchmove', preventScroll);
        };
    }, [withBodyTouchBlocked]);
};

export const useDesktopSlider = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize.width >= SLIDER_FULL_WIDTH;
};

// SECTION: Functions
export const getTranslateXGalleryValue = (length: number, index: number, slideWidth: number, gap: number) => {
    const startOffset = slideWidth * length + gap * (length - 1) - slideWidth / 2 - gap / 2;
    const slideOffset = index * slideWidth + (index - 1) * gap;

    return `translateX(${startOffset - slideOffset}px)`;
};

export const getTranslateXCardsValue = (length: number, index: number, slideWidth: number, gap: number) => {
    const startOffset = slideWidth * length + gap * (length - 1);
    const slideOffset = index * slideWidth + (index - 1) * gap;

    return `translateX(${startOffset - slideOffset}px)`;
};

export const getSlideIndexes = (slidesArray: unknown[], activeSlideIndex: number, additionalElementsNumber: number) => {
    const slideAmount = slidesArray.length;

    return {
        slideAmount,
        leftTransitionSlideIndex: additionalElementsNumber - 1,
        rightTransitionSlideIndex: slideAmount + additionalElementsNumber,
        leftEdgeSlideIndex: additionalElementsNumber,
        rightEdgeSlideIndex: slideAmount + additionalElementsNumber - 1,
        actualActiveSlideIndex: (activeSlideIndex - additionalElementsNumber + slideAmount) % slideAmount,
    };
};
