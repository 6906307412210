import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { HomePage } from 'pages/Home';
import { UnknownPage } from 'pages/404';
import { LegalPage } from 'pages/Legal';

import { PRIVACY_PAGE_LINK, PRIVACY_POLICY_PAGE_CONTENT, TERMS_OF_USE_CONTENT, TERMS_PAGE_LINK } from '../constants';

export const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path={`/${PRIVACY_PAGE_LINK}`} element={<LegalPage content={PRIVACY_POLICY_PAGE_CONTENT} />} />
            <Route path={`/${TERMS_PAGE_LINK}`} element={<LegalPage content={TERMS_OF_USE_CONTENT} />} />
            <Route path="*" element={<UnknownPage />} />
        </Routes>
    </BrowserRouter>
);
